import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"

export default function OrbitImages({ slides = [], activeSlide = 0 }) {
  useGSAP(
    () => {
      if (slides.length > 0 && activeSlide) {
        const images = gsap.utils.toArray(".orbit-images")
        images.length > 0 &&
          images.forEach((img, i) => {
            if (i === activeSlide - 1) {
              gsap.to(`#orbit-image-${i}`, {
                autoAlpha: 0.75,
                duration: 0.8,
                ease: "power2.out",
              })
            } else {
              gsap.to(`#orbit-image-${i}`, {
                autoAlpha: 0,
                duration: 0.8,
                ease: "power2.out",
              })
            }
          })
      }
    },
    { dependencies: [slides, activeSlide] },
  )

  return (
    <>
      {slides &&
        slides.length > 0 &&
        slides.map((slide, i) => {
          const { content } = slide

          if (content?.url) {
            return (
              <img
                key={i}
                className={`orbit-images`}
                id={`orbit-image-${i}`}
                src={content?.url}
                alt={content?.copy || "Orbit Slide"}
                style={{
                  visibility: "hidden",
                  opacity: 0,
                  gridColumn: "1 / -1",
                  gridRow: "1 / -1",
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  overflow: "hidden",
                  zIndex: 1,
                }}
              />
            )
          }
        })}
    </>
  )
}
