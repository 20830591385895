import ErrorMessage from "@pages/ErrorMessage"
import {
  VITE_PAYLOAD_PROJECTS_ENDPOINT,
  VITE_PAYLOAD_SERVER_URL,
} from "@utils/env"
import React, { useEffect } from "react"
import { Routes, Route, useLocation } from "react-router-dom"
import { motion, AnimatePresence } from "framer-motion"
import { useLivePreview } from "@payloadcms/live-preview-react"

const framerProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

interface Route {
  path: string
  element: React.ComponentType
}

interface ContentProps {
  routes: Route[] | null
}

const Content: React.FC<ContentProps> = ({ routes = null }) => {
  const allRoutes = [
    { path: "/*", name: "Not Found", element: ErrorMessage },
    ...routes,
  ]
  const location = useLocation()

  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          {allRoutes &&
            allRoutes.length > 1 &&
            allRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.6 }}
                    style={{
                      height: "100%",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <route.element />
                  </motion.div>
                }
              />
            ))}
        </Routes>
      </AnimatePresence>
    </>
  )
}

export default Content
