import { useGSAP } from "@gsap/react"
import NavLinkComponent from "./NavLink"

export default function NavLinks({ navList }) {
  return (
    <div
      style={{
        display: "grid",
        gridAutoFlow: "row",
        placeSelf: "start start",
        placeItems: "center start",
        rowGap: "3rem",
        overflow: "hidden",
        padding: "0.7rem",
      }}
    >
      {navList.map((navItem, i) => {
        const { path, label } = navItem
        return <NavLinkComponent key={i} path={path} label={label} />
      })}
    </div>
  )
}
