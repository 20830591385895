import Landing from "@pages/Landing"
import ErrorMessage from "@pages/ErrorMessage"
import { fetchProjects } from "./fetchRoutes"
import SplashPage from "@page_components/SplashPage/SplashPage"
import GalleryPage from "@page_components/GalleryPage/GalleryPage"
import ResidencesPage from "@page_components/ResidencesPage/ResidencesPage"
import OrbitPage from "@page_components/OrbitPage/OrbitPage"
import { useLivePreview } from "@payloadcms/live-preview-react"
import { VITE_PAYLOAD_SERVER_URL } from "./env"
import FavouritesPage from "@page_components/FavouritesPage/FavouritesPage"

export const generateRoutes = (data?: any) => {
  const projectRoutes = data.pages.map((page: any) => {
    const shortenedID = page.blockType.split("-page")[0]
    const path = shortenedID === "splash" ? "/" : `/${shortenedID}`
    const navLabel = page.navLabel || null
    let element
    if (page.blockType === "splash-page") {
      element = () => <SplashPage {...page} />
    } else if (page.blockType === "gallery-page") {
      element = () => <GalleryPage {...page} />
    } else if (page.blockType === "residences-page") {
      element = () => <ResidencesPage {...page} />
    } else if (page.blockType === "orbit-page") {
      element = () => <OrbitPage {...page} />
    } else if (page.blockType === "favourites-page") {
      element = () => <FavouritesPage {...page} />
    } else {
      return
    }
    return {
      path,
      element,
      navLabel,
    }
  })

  return [...projectRoutes]
}
