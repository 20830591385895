import { globalStyles } from "@data/globalStyles"
import { useStore } from "@state/store"
import { useLocation, useNavigate } from "react-router-dom"

export default function Button({ buttonCopy }) {
  const navigate = useNavigate()
  const syncState = useStore((state) => state.syncState)
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        display: "grid",
        placeSelf: "end center",
        placeItems: "end center",
        padding: "4.5rem",
      }}
    >
      {buttonCopy && (
        <button
          onClick={() => {
            syncState({ collapseNav: false })
            navigate("/residences")
          }}
          style={{
            backgroundColor: globalStyles.col.white,
            padding: "1.3rem 2.6rem 1.3rem 4rem",
          }}
        >
          <span style={{ color: globalStyles.col.black }}>{buttonCopy}</span>
          <span
            style={{
              height: "1px",
              backgroundColor: globalStyles.col.black,
              width: "4rem",
              border: "none",
            }}
          />
        </button>
      )}
    </div>
  )
}
