import LeftArrowSVG from "@components/SVGs/LeftArrowSVG"
import RightArrowSVG from "@components/SVGs/RightArrowSVG"
import { useState } from "react"

export default function OrbitButtons({
  activeSlide,
  setActiveSlide,
  slideCount,
}) {
  const [disable, setDisable] = useState(false)
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        zIndex: 2,
        // paddingLeft: "24rem",
        paddingBottom: "4rem",
        placeSelf: "end start",
        display: "grid",
        placeItems: "center",
        gridAutoFlow: "column",
        columnGap: "1.3rem",
        height: "4rem",
      }}
    >
      <LeftArrowSVG
        style={{
          height: "100%",
          aspectRatio: 1,
          zIndex: 3,
          cursor: activeSlide - 1 < 1 || disable ? "auto" : "pointer",
          opacity: activeSlide - 1 < 1 || disable ? 0.5 : 1,
          transition: "opacity 0.3s",
        }}
        onClick={() => {
          if (activeSlide - 1 < 1 || disable) return
          setActiveSlide(activeSlide - 1)
          setDisable(true)
          setTimeout(() => setDisable(false), 1000)
        }}
      />
      <RightArrowSVG
        style={{
          height: "100%",
          aspectRatio: 1,
          zIndex: 3,
          cursor: activeSlide + 1 > slideCount || disable ? "auto" : "pointer",
          opacity: activeSlide + 1 > slideCount || disable ? 0.5 : 1,
          transition: "opacity 0.3s",
        }}
        onClick={() => {
          if (activeSlide + 1 > slideCount || disable) return

          setActiveSlide(activeSlide + 1)
          setDisable(true)
          setTimeout(() => setDisable(false), 1000)
        }}
      />
    </div>
  )
}
