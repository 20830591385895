import { useStore } from "@state/store"

export default function ResidencePopup({ data }) {
  const activeResidence = useStore((s) => s.activeResidence)
  const syncState = useStore((s) => s.syncState)
  const getFloorPlan = (unitID, data): any => {
    const unit = data?.find((item) => String(item?.unitID) === String(unitID))

    return unit?.floorPlan?.url
  }

  return (
    <div
      id="residence-popup"
      style={{
        visibility: "hidden",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
      }}
    >
      {activeResidence && (
        <img
          onClick={() => syncState({ residencePopup: false })}
          alt="Floor Plan"
          src={getFloorPlan(activeResidence, data)}
          style={{
            cursor: "pointer",
            height: "100%",
            width: "100%",
            maxHeight: "50rem",
            maxWidth: "90rem",
            objectFit: "contain",
            objectPosition: "center",
            overflow: "hidden",
            zIndex: 2,
          }}
        />
      )}
    </div>
  )
}
