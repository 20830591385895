import ShadowBackground from "@components/Backgrounds/ShadowBackground"
import FavouritesBackground from "./FavouritesBackground"
import FavouritesCarousel from "./FavouritesCarousel"
import FavouritesShareButton from "./FavouritesShareButton"
import FavouritesShareCard from "./FavouritesShareCard"

export default function FavouritesPage({ ...page }: any) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        zIndex: 0,
        overflow: "hidden",
      }}
    >
      <ShadowBackground opacity={0.9} />
      <FavouritesShareButton />
      <FavouritesBackground />
      <FavouritesCarousel />
      <FavouritesShareCard />
    </div>
  )
}
