import { useEffect } from "react"
import Button from "./Button"
import Logo from "./Logo"
import Video from "./Video"
import { useStore } from "@state/store"
import { useLivePreview } from "@payloadcms/live-preview-react"
import { VITE_PAYLOAD_SERVER_URL } from "@utils/env"

export default function SplashPage({ ...page }) {
  const { logo, buttonCopy } = page
  const syncState = useStore((state) => state.syncState)

  useEffect(() => {
    syncState({ collapseNav: true })
  }, [])
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        overflow: "hidden",
      }}
    >
      <Video />
      {<Logo logo={logo} />}
      {<Button buttonCopy={buttonCopy} />}
    </div>
  )
}
