import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"
import Logo from "./Logo"
import NavLinks from "./NavLinks"
import { useStore } from "@state/store"

export default function Sidebar({ navList }) {
  const collapseNav = useStore((state) => state.collapseNav)
  useGSAP(
    () => {
      gsap.to("#nav", {
        x: collapseNav ? -300 : 0,
        duration: 0.5,
        ease: "power2.inOut",
      })
    },
    { dependencies: [collapseNav] },
  )
  return (
    <div
      id="nav"
      style={{
        height: "100%",
        width: "100%",
        zIndex: 2,
        padding: "5rem 2rem 0rem 2rem",
        display: "grid",
        gridColumn: 1,
        gridRow: "1 / -1",
        placeSelf: "center start",
        gridAutoFlow: "row",
        overflow: "hidden",
        transform: "translateX(-100%)",
        transition: "width 0.5s ease-in-out",
        background:
          "linear-gradient(0deg,  rgba(219, 214, 194, 0.9) 0%,  rgba(219, 214, 194, 0.8) 100%),rgba(255, 255, 255, 0.7)",
      }}
    >
      <Logo />
      <NavLinks navList={navList} />
    </div>
  )
}
