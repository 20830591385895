import { useStore } from "@state/store"

const paths = {
  1: {
    d: "M1391 1886V1773.5L1743 1807.5L1907.72 1794.5V1907L1743 1922.5L1391 1886Z",
  },
  2: {
    d: "M1391 1736V1627.5L1739.85 1652L1907.72 1643V1758L1739.85 1768L1391 1736Z",
  },
  3: {
    d: "M1392 1589.04V1481.5L1740.85 1495.5L1908.72 1489.5V1606L1740.85 1614.5L1392 1589.04Z",
  },
  4: {
    d: "M1390.54 1442.5V1334.96L1747.26 1340.2L1907.26 1334.96V1452.99H1739.39L1390.54 1442.5Z",
  },
  5: {
    d: "M1386.5 1185V1298.5H1745H1909V1181H1745L1386.5 1185Z",
  },
}

export default function TwoDimensionalResidenceFinder({ data }) {
  const syncState = useStore((s) => s.syncState)
  const clickEvent = (id) => {
    syncState({ residencePopup: true, activeResidence: id })
  }
  const getFillColor = (unitID, data): any => {
    const unit = data?.find((item) => String(item?.unitID) === String(unitID))

    return unit?.status === "available" ? "#9ad426" : "#dc2b2b"
  }
  return (
    <div
      style={{
        display: "grid",
        placeSelf: "center",
        placeItems: "center",
        height: "75%",
        padding: "2rem 0rem",
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 3840 3930"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <image href="/assets/imgs/2DModel.png" height={3930} width={3840} />

        {data &&
          data.length > 0 &&
          data.map((unit, index) => {
            const { unitID } = unit
            if (!paths[unitID]) return null
            return (
              <path
                key={index}
                id={unitID}
                d={paths[unitID]?.d}
                fill={getFillColor(unitID, data)}
                onClick={() => clickEvent(unitID)}
                style={{
                  // mixBlendMode: "color",
                  opacity: 0.6,
                  cursor: "pointer",
                }}
              />
            )
          })}
      </svg>
    </div>
  )
}
