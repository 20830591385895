export default function ShadowBackground({ opacity = 0.9 }) {
  return (
    <img
      alt="Background Shadow"
      src="/assets/imgs/residences-bg.png"
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        objectPosition: "center",
        objectFit: "cover",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        mixBlendMode: "soft-light",
        opacity: opacity,
      }}
    />
  )
}
