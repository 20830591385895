import { useStore } from "@state/store"
import { useNavigate } from "react-router-dom"

export default function Logo() {
  const navigate = useNavigate()
  const syncState = useStore((state) => state.syncState)
  return (
    <img
      onClick={() => {
        syncState({ collapseNav: true })
        navigate("/")
      }}
      alt="Log"
      src="/assets/imgs/trillium-logo-black.png"
      style={{
        overflow: "hidden",
        placeSelf: "start center",
        height: "auto",
        width: "80%",
        maxHeight: "33%",
        maxWidth: "100%",
        objectFit: "contain",
        cursor: "pointer",
      }}
    />
  )
}
