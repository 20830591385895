import HeartSVG from "@components/SVGs/HeartSVG"
import { globalStyles } from "@data/globalStyles"
import { useStore } from "@state/store"
import numWords from "num-words"

export default function Table({ data }) {
  const syncState = useStore((s) => s.syncState)
  const cellStyle = {
    padding: "0 1.5rem",
  }
  return (
    <table
      style={{
        borderCollapse: "collapse",
        width: "50%",
        zIndex: 1,
        fontFamily: "Baskerville, serif",
        fontWeight: "100",
        fontStyle: "normal",
        fontSize: "0.8rem",
        paddingTop: "12rem",
        paddingLeft: "4rem",
      }}
    >
      <thead
        style={{
          backgroundColor: globalStyles.col.black,
        }}
      >
        <tr
          style={{
            color: globalStyles.col.lightYellow,
            height: "3rem",
            letterSpacing: "0.4em",
            textTransform: "uppercase",
            textAlign: "left",
          }}
        >
          <th style={{ ...cellStyle }}>Apartment</th>
          <th style={{ ...cellStyle }}>Price</th>
          <th style={{ ...cellStyle }}>Bedrooms</th>
          <th style={{ ...cellStyle }}>Facing</th>
          <th style={{ ...cellStyle }}>Floor</th>
          <th style={{ ...cellStyle }}></th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => (
          <tr
            key={i}
            style={{
              height: "4rem",
              borderBottom: `1px solid ${globalStyles.col.white}`,
              backgroundColor: globalStyles.col.middleYellow,
              fontSize: "1.1rem",
              lineHeight: "2rem",
              letterSpacing: "0.08rem",
              color: globalStyles.col.black,
              fontWeight: 50,
              cursor: "pointer",
            }}
            onClick={() => {
              syncState({ residencePopup: true, activeResidence: item?.unitID })
            }}
          >
            <td style={{ ...cellStyle }}>Unit {item?.unitID}</td>
            <td style={{ ...cellStyle }}>£{item?.price?.toLocaleString()}</td>
            <td style={{ textTransform: "capitalize", ...cellStyle }}>
              {numWords(item?.bedrooms)}
            </td>
            <td style={{ textTransform: "uppercase", ...cellStyle }}>
              {item.facing.map((f) => f.charAt(0)) || "N/A"}
            </td>
            <td style={{ ...cellStyle }}>{item?.floor}</td>
            <td style={{ ...cellStyle }}>
              <HeartSVG id={item?.unitID} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
