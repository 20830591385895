import { globalStyles } from "@data/globalStyles"

export default function OrbitSlideCount({ activeSlide = 1, slideCount = 1 }) {
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        zIndex: 2,
        placeSelf: "end",
        padding: "4rem 10rem",
        fontSize: "2rem",
        lineHeight: "2rem",
        fontStyle: "normal",
        color: globalStyles.col.white,

        fontFamily: "Baskerville",
      }}
    >
      <span
        id="orbit-slide-count"
        style={{
          fontSize: "3rem",
          lineHeight: "3rem",
          paddingRight: "1rem",
          fontFamily: "Quiche Sans Regular",
        }}
      >
        {activeSlide < 10 && 0}
        {activeSlide}
      </span>
      <span
        style={{
          paddingRight: "1rem",
          fontSize: "1.4rem",
          lineHeight: "1rem",
        }}
      >
        /
      </span>
      <span id="orbit-slide-total">
        {slideCount < 10 && 0}
        {slideCount}
      </span>
    </div>
  )
}
