import { globalStyles } from "@data/globalStyles"

export default function FavouritesBackground() {
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        backgroundColor: globalStyles.col.sandy,
        opacity: 0.7,
        height: "100%",
        width: "100%",
      }}
    />
  )
}
