export default function Video() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        display: "grid",
        placeSelf: "center",
        placeItems: "center",
        overflow: "hidden",
      }}
    >
      <video
        src="/assets/videos/bg.mp4"
        autoPlay
        loop
        muted
        playsInline
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          height: "100%",
          width: "100%",
          objectFit: "cover",
          objectPosition: "center",
          zIndex: -1,
          overflow: "hidden",
        }}
      />
    </div>
  )
}
