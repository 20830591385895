import { globalStyles } from "@data/globalStyles"
import ShadowBackground from "../../components/Backgrounds/ShadowBackground"
import Table from "./Table"
import TwoDimensionalResidenceFinder from "./2DFinder"
import { useGSAP } from "@gsap/react"
import { useStore } from "@state/store"
import { gsap } from "gsap"
import ResidencePopup from "./ResidencePopup"

export default function ResidencesPage({ ...page }: any) {
  const residencePopup = useStore((s) => s.residencePopup)

  const data = page?.inventoryList?.unit
  useGSAP(
    () => {
      gsap.to("#residence-main", {
        autoAlpha: residencePopup ? 0 : 1,
        duration: 0.5,
      })
      gsap.to("#residence-popup", {
        autoAlpha: residencePopup ? 1 : 0,
        duration: 0.5,
      })
    },
    { dependencies: [residencePopup] },
  )
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: globalStyles.col.lightYellow,
        display: "grid",
        zIndex: 0,
      }}
    >
      <ShadowBackground />
      <div
        id="residence-main"
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          display: "grid",
          gridAutoFlow: "column",
          placeItems: "start start",
          zIndex: 1,
          overflow: "hidden",
          padding: "4rem 5.5rem",
        }}
      >
        {data?.length > 0 && <Table data={data} />}
        {data?.length > 0 && <TwoDimensionalResidenceFinder data={data} />}
      </div>
      <ResidencePopup data={data} />
    </div>
  )
}
