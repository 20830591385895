export default function LeftArrowSVG({ ...props }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="60"
        height="60"
        transform="matrix(-1 0 0 1 60 0)"
        fill="white"
        fillOpacity="0.12"
      />
      <path
        d="M36.875 30H23.125"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.75 24.375L23.125 30L28.75 35.625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
