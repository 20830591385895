export default function GalleryPage({ ...page }) {
  const images = page?.galleryImages
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        gridAutoFlow: "row",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridTemplateRows: "repeat(2, 1fr)",
        // paddingLeft: "18.4rem",
        overflow: "hidden",
      }}
    >
      {images &&
        images.length > 0 &&
        images.map((image, i) => {
          const { content } = image

          if (content?.url) {
            return (
              <img
                key={i}
                src={content?.url}
                alt={content?.copy || "Gallery Image"}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  overflow: "hidden",
                }}
              />
            )
          }
        })}
    </div>
  )
}
