import React from "react"

export default function CloseSVG({ ...props }) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.25 1.25002L1.00002 12.5" stroke="white" />
      <path d="M12.25 12.25L0.999983 1.00002" stroke="white" />
    </svg>
  )
}
