export const globalStyles = {
  col: {
    black: "#2B2525",
    white: "#fff",
    whiteYellow: "#ffffff60",
    lightYellow: "#DBD6C2",
    middleYellow: "#EDE7D2",
    gold: "#CDAA78",
    sandy: "#D5CFB9",
    lightGrey: "#8D8881",
  },
}
