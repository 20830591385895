import { globalStyles } from "@data/globalStyles"
import { NavLink } from "react-router-dom"

export default function NavLinkComponent({ path, label }) {
  return (
    <NavLink
      to={path}
      style={({ isActive }) => ({
        textTransform: "uppercase",
        fontWeight: 100,
        color: globalStyles.col.black,
        fontSize: "0.9rem",
        lineHeight: "1rem",
        textAlign: "left",
        placeSelf: "center start",
        placeItems: "center start",
        columnGap: isActive ? "1rem" : "0rem",
      })}
    >
      {({ isActive }) => (
        <>
          <span
            style={{
              height: "1px",
              backgroundColor: globalStyles.col.black,
              width: isActive ? "3rem" : "0rem",
              border: "none",
              transition: "width 0.3s",
            }}
          />
          <span>{label}</span>
        </>
      )}
    </NavLink>
  )
}
