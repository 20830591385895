import { useState } from "react"
import OrbitImages from "./OrbitImages"
import OrbitSlideCount from "./OrbitSlideCount"
import OrbitButtons from "./OrbitButtons"
import OrbitCopy from "./OrbitCopy"

export default function OrbitPage({ ...page }) {
  const slides = page?.orbitSlides
  const slideCount = slides?.length
  const [activeSlide, setActiveSlide] = useState(1)
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        overflow: "hidden",
        backgroundColor: "black",
      }}
    >
      {slides && slides?.length > 0 && (
        <>
          <OrbitImages slides={slides} activeSlide={activeSlide} />
          <OrbitSlideCount activeSlide={activeSlide} slideCount={slideCount} />
          <OrbitButtons
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            slideCount={slideCount}
          />
          <OrbitCopy
            slides={slides}
            activeSlide={activeSlide}
            slideCount={slideCount}
          />
        </>
      )}
    </div>
  )
}
