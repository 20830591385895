import { globalStyles } from "@data/globalStyles"
import { useStore } from "@state/store"
import styles from "./page.module.css"
import QRCode from "qrcode.react"
import React from "react"

export default function FavouritesShareCard() {
  const favourites = useStore((state) => state.favourites)
  const shareFavourites = useStore((state) => state.shareFavourites)
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [qrValue, setQrValue] = React.useState("")
  const [uniqueCode, setUniqueCode] = React.useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setQrValue("")

    console.log(favourites.map((f) => f))
    try {
      const response = await fetch(
        `${import.meta.env.VITE_PAYLOAD_SERVER_URL}/api/customers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            favourites: favourites.map((f) => f),
          }),
        },
      )

      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      const data = await response.json()
      const documentId = data?.doc?.userID
      if (documentId) {
        const uniqueLink = `https://share-berkeley.vmistudio.com/${documentId}`
        console.log(uniqueLink)
        setQrValue(uniqueLink)
        setUniqueCode(documentId)
        setLoading(false)
        console.log(documentId)
      } else {
        setLoading(false)
        console.error("Document ID not found in response:", data)
      }
    } catch (error) {
      setLoading(false)
      console.error("Error creating customer:", error)
    }
  }

  return (
    <div
      style={{
        opacity: shareFavourites ? 1 : 0,
        transition: "opacity 0.8s ease-in-out",
        pointerEvents: shareFavourites ? "auto" : "none",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        display: "grid",
        zIndex: 3,
        padding: "4rem 4rem 4rem 14rem",
        placeSelf: "center",
        maxHeight: "60%",
        gridTemplateColumns: "minmax(0, 2fr) minmax(0, 1fr)",
        fontSize: "2rem",
        color: globalStyles.col.black,
        gap: "2rem",
      }}
    >
      <p
        style={{
          placeSelf: "end start",
          gridColumn: 1,
          gridRow: 1,
          fontSize: "4rem",
          lineHeight: "4rem",
        }}
      >
        View your favourites
      </p>
      <p
        style={{
          placeSelf: "start start",
          gridColumn: 1,
          gridRow: 2,
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate
        libero et velit interdum, ac aliquet odio mattis. Class aptent liubia
        nostra, per inceptos himenaeos.
      </p>
      <form
        onSubmit={handleSubmit}
        style={{
          placeSelf: "start start",
          gridColumn: 1,
          gridRow: 3,
          display: "grid",
          placeItems: "center",
          gridAutoFlow: "column",
          fontFamily: "Baskerville",
          gap: "0.3rem",
        }}
      >
        <input
          value={name}
          onChange={(e) => {
            setName(e.target.value)
          }}
          required
          className={styles.inputPlaceholder}
          type="text"
          placeholder="Name"
          style={{
            backgroundColor: globalStyles.col.whiteYellow,
            padding: "1rem",
            border: "none",
            fontFamily: "Baskerville",
            letterSpacing: "0.1rem",
            color: globalStyles.col.black,
          }}
        />
        <input
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          required
          className={styles.inputPlaceholder}
          type="text"
          placeholder="Email"
          style={{
            backgroundColor: globalStyles.col.whiteYellow,
            padding: "1rem",
            border: "none",
            fontFamily: "Baskerville",
            letterSpacing: "0.1rem",
            color: globalStyles.col.black,
          }}
        />
        <button
          style={{
            backgroundColor: loading ? "transparent" : globalStyles.col.gold,
            height: "100%",
            width: "100%",
            color: globalStyles.col.white,
            padding: "1rem 2rem",
            fontSize: "1rem",
            textTransform: "uppercase",
          }}
          type="submit"
        >
          {loading ? (
            <div className={styles.spinner}></div>
          ) : (
            "Generate QR Code"
          )}
        </button>
      </form>
      {}
      <div
        style={{
          gridColumn: 2,
          gridRow: "1 / 4",
          height: "100%",
          width: "100%",
          display: "grid",
          placeItems: "center",
          padding: "3rem",
        }}
      >
        {qrValue && (
          <QRCode
            bgColor={"transparent"}
            fgColor={globalStyles.col.gold}
            // fgColor={`linear-gradient(134deg, #CBAA76 -9.46%, #B7935A 53%, #E2CC9C 115.45%)`}
            style={{
              height: "100%",
              width: "100%",
              //   aspectRatio: 1,
            }}
            value={qrValue}
          />
        )}
        {uniqueCode && (
          <p
            style={{
              marginTop: "1rem",
              fontSize: "1.6rem",
              textAlign: "center",
              fontFamily: "Quiche Sans Light",
              color: globalStyles.col.gold,
            }}
          >
            Your unique user code: <br />
            <b style={{ fontFamily: "Baskerville SemiBold" }}>{uniqueCode}</b>
          </p>
        )}
      </div>
    </div>
  )
}
