import { useStore } from "@state/store"

export default function HeartSVG({ id, ...props }) {
  const favourites = useStore((s) => s.favourites)
  const syncState = useStore((s) => s.syncState)
  const isFavourite = favourites?.includes(id)

  return (
    <svg
      width="1.4rem"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        e.stopPropagation()
        isFavourite
          ? syncState({ favourites: favourites?.filter((f) => f !== id) })
          : syncState({ favourites: [...favourites, id] })
      }}
      {...props}
    >
      <path
        d="M10.0005 16.875C10.0005 16.875 2.18796 12.5 2.18796 7.18751C2.18812 6.24855 2.51347 5.33863 3.1087 4.61244C3.70392 3.88626 4.53229 3.38863 5.45295 3.20418C6.37361 3.01972 7.32974 3.15982 8.15878 3.60066C8.98782 4.04149 9.6386 4.75585 10.0005 5.62227L10.0004 5.62228C10.3623 4.75585 11.0131 4.04149 11.8421 3.60066C12.6712 3.15983 13.6273 3.01973 14.548 3.20418C15.4686 3.38863 16.297 3.88625 16.8922 4.61244C17.4874 5.33862 17.8128 6.24855 17.813 7.18751C17.813 12.5 10.0005 16.875 10.0005 16.875Z"
        fill={isFavourite ? "#CDAA78" : "transparent"}
        stroke={isFavourite ? "#CDAA78" : "#2B2525"}
        style={{
          transition: "fill 0.3s, stroke 0.3s",
          cursor: "pointer",
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
