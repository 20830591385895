import { BrowserRouter, Route, Routes } from "react-router-dom"
import DefaultLayout from "./DefaultLayout"

function App() {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  )
}

export default App

const AnimatedRoutes = () => {
  return (
    <Routes location={location} key={location.pathname}>
      <Route path="*" element={<DefaultLayout />} />
    </Routes>
  )
}
