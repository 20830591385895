import { globalStyles } from "@data/globalStyles"

export default function FavouritesCard({ favourite }) {
  return (
    <div
      style={{
        border: `1px solid ${globalStyles.col.gold}`,
        padding: "4rem",
        display: "grid",
        gridAutoFlow: "row",
        gap: "1rem",
        overflow: "hidden",
      }}
    >
      <p style={{ fontSize: "4rem" }}>Apartment {favourite}</p>
    </div>
  )
}
