import { VITE_PAYLOAD_PROJECTS_ENDPOINT, VITE_PAYLOAD_SERVER_URL } from "./env"

export const fetchProjects = async () => {
  const date = new Date()
  const response = await fetch(
    `${VITE_PAYLOAD_SERVER_URL}${VITE_PAYLOAD_PROJECTS_ENDPOINT}&date=${date.getDate()}`,
  )
  if (!response.ok) {
    throw new Error("Failed to fetch projects")
  }
  return response.json()
}
