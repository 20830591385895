export default function Logo({ logo }) {
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        display: "grid",
        placeSelf: "start center",
        placeItems: "start center",
        padding: "8rem",
      }}
    >
      {logo?.url && (
        <img
          src={logo?.url}
          style={{
            height: "100%",
            width: "100%",
            maxHeight: "10rem",
            maxWidth: "20rem",
            objectFit: "cover",
            zIndex: 1,
            overflow: "hidden",
          }}
        />
      )}
    </div>
  )
}
