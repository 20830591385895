import { useStore } from "@state/store"
import FavouritesCard from "./FavouritesCard"

export default function FavouritesCarousel() {
  const favourites = useStore((state) => state.favourites)
  const shareFavourites = useStore((state) => state.shareFavourites)
  return (
    <div
      style={{
        opacity: shareFavourites ? 0 : 1,
        transition: "opacity 0.8s ease-in-out",
        pointerEvents: shareFavourites ? "none" : "auto",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        paddingTop: "18rem",
        paddingBottom: "6rem",
        paddingLeft: "22rem",
        zIndex: 2,
        display: "grid",
        gridAutoFlow: "column",
        overflow: "hidden",
      }}
    >
      {favourites &&
        favourites?.length > 0 &&
        favourites.map((favourite, index) => {
          return <FavouritesCard key={index} favourite={favourite} />
        })}
    </div>
  )
}
