import Content from "@src/Content"
import Sidebar from "@components/Sidebar/Sidebar"
import { useLivePreview } from "@payloadcms/live-preview-react"
import { VITE_PAYLOAD_SERVER_URL } from "@utils/env"
import { generateRoutes } from "@utils/generateRoutes"
import { useEffect, useState } from "react"

export default function RoutesBuilder({ projects }) {
  const { data } = useLivePreview({
    initialData: projects,
    serverURL: VITE_PAYLOAD_SERVER_URL,
    depth: 2,
  })

  const [routes, setRoutes] = useState<any[]>([])
  const [navLinks, setNavLinks] = useState<any[]>([])

  useEffect(() => {
    if (data) {
      const generatedRoutes = generateRoutes(data)
      setRoutes(generatedRoutes)
      const generatedNavLinks = generatedRoutes
        .map((route) => ({
          label: route.navLabel,
          path: route.path,
        }))
        .filter((route) => route.label !== undefined)
      setNavLinks(generatedNavLinks)
    }
  }, [data])

  return (
    <>
      <Sidebar navList={navLinks} />
      <div
        style={{
          gridColumn: 2,
          gridRow: "1 / -1",
          height: "100%",
          width: "100%",
          display: "grid",
          overflow: "hidden",
          transition: "width 0.5s ease-in-out",
        }}
      >
        <Content routes={routes} />
      </div>
    </>
  )
}
