import { create } from "zustand"

interface InitialState {
  syncState: (stateToUpdate: any) => void
  collapseNav: boolean
  data: any[]
  favourites: any[]
  shareFavourites: boolean
  residencePopup: boolean
  activeResidence: string
}

export const useStore = create<InitialState>()((set, get) => ({
  syncState: (stateToUpdate) => {
    set({ ...stateToUpdate })
  },
  collapseNav: false,
  data: [],
  favourites: ["1", "2", "3"],
  shareFavourites: false,
  residencePopup: false,
  activeResidence: null,
}))
