import { useEffect, useMemo, useState } from "react"
import { fetchProjects } from "@utils/fetchRoutes"
import RoutesBuilder from "@components/RoutesBuilder/RoutesBuilder"
import { useStore } from "@state/store"
import { useLocation } from "react-router-dom"

export const DefaultLayout = () => {
  const [projects, setProjects] = useState<any>(null)
  const location = useLocation()
  const favourites = useStore((state) => state.favourites)

  useMemo(() => {
    const loadProjects = async () => {
      try {
        const projects = await fetchProjects()
        setProjects(projects)
      } catch (error) {
        console.error("Error loading projects:", error)
      }
    }

    loadProjects()
  }, [])

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        gridAutoFlow: "column",
        placeItems: "center",
        overflow: "hidden",
        gridTemplateColumns: `minmax(0, ${
          location.pathname !== "/" ? 0.9 : 0
        }fr) minmax(0, 5.8fr)`,
        transition: "grid-template-columns 0.5s ease-in-out",
      }}
    >
      <RoutesBuilder projects={projects} />
    </div>
  )
}

export default DefaultLayout
