export default function RightArrowSVG({ ...props }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="60" height="60" fill="white" fillOpacity="0.12" />
      <path
        d="M23.125 30H36.875"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.25 24.375L36.875 30L31.25 35.625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
