import CloseSVG from "@components/SVGs/CloseSVG"
import ShareSVG from "@components/SVGs/ShareSVG"
import { globalStyles } from "@data/globalStyles"
import { useStore } from "@state/store"
import React from "react"

export default function FavouritesShareButton() {
  const shareFavourites = useStore((state) => state.shareFavourites)
  const syncState = useStore((state) => state.syncState)
  return (
    <div
      style={{
        gridColumn: "1/-1",
        gridRow: "1/-1",
        paddingLeft: "4rem",
        paddingTop: "4rem",
        placeSelf: "start",
        display: "grid",
        gridAutoFlow: "row",
        gap: "2rem",
        zIndex: 3,
      }}
    >
      <button
        onClick={() => {
          syncState({ shareFavourites: !shareFavourites })
        }}
        style={{
          cursor: "pointer",
          pointerEvents: "auto",
          aspectRatio: 1,
          height: "5rem",
          backgroundColor: globalStyles.col.black,
          padding: "1.4rem",
        }}
      >
        <ShareSVG
          style={{
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            opacity: shareFavourites ? 0 : 1,
            transition: "opacity 0.8s ease-in-out",
            height: "100%",
            width: "100%",
          }}
        />
        <CloseSVG
          style={{
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            opacity: shareFavourites ? 1 : 0,
            transition: "opacity 0.8s ease-in-out",
            height: "100%",
            width: "100%",
          }}
        />
      </button>
      <p
        style={{
          transform: "translate(0, 1rem) rotate(-90deg)",
          fontFamily: "Baskerville",
          color: globalStyles.col.black,
          letterSpacing: "0.2rem",
          display: "grid",
          placeItems: "center",

          transformOrigin: "center center",
        }}
      >
        <span
          style={{
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            opacity: shareFavourites ? 0 : 1,
            transition: "opacity 0.8s ease-in-out",
          }}
        >
          SHARE
        </span>
        <span
          style={{
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            opacity: shareFavourites ? 1 : 0,
            transition: "opacity 0.8s ease-in-out",
          }}
        >
          CLOSE
        </span>
      </p>
    </div>
  )
}
