import { globalStyles } from "@data/globalStyles"
import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"
import { useEffect, useRef } from "react"
import SplitType from "split-type"

// `0 = -40vh`
// `1 = -20vh`
// `2 = 0vh`

export default function OrbitCopy({
  slides = [],
  activeSlide = 1,
  slideCount = 1,
  fadeOutTime = 300, //milliseconds
}) {
  // const splitInstances = useRef([])
  const timeline = useRef<any>(null)

  useGSAP(
    () => {
      timeline.current = gsap.timeline()
      if (slides?.length > 0 && activeSlide && timeline.current) {
        // const images = gsap.utils.toArray(".orbit-copy")
        // splitInstances.current.forEach((instance) => instance.revert())
        // splitInstances.current = []

        timeline.current.pause()
        timeline.current.clear()

        slides?.length > 0 &&
          slides?.forEach((slide, i) => {
            if (!slide?.content || !slide?.title || !slide?.subtitle) return
            if (i === activeSlide - 1) {
              const title = new SplitType(`#orbit-copy-${i}-title`)
              const subtitle = new SplitType(`#orbit-copy-${i}-subtitle`)
              // splitInstances.current.push(title, subtitle)

              timeline.current.to("#orbit-copy-div", {
                y: `-${(slideCount - activeSlide) * (40 / slideCount)}rem`,
              })

              gsap.set([`#orbit-copy-${i}`, title?.chars, subtitle?.lines], {
                autoAlpha: 0,
              })

              timeline.current.to(
                [`#orbit-copy-${i}`, title?.chars, subtitle?.lines],
                {
                  autoAlpha: 1,
                  duration: (fadeOutTime / 1000) * 1.3,
                  ease: "none",
                  delay: 0.2,

                  stagger: {
                    each: 0.03,
                    ease: "power.out",
                  },
                },
              )
              timeline.current.play()
            } else {
              gsap.to(`#orbit-copy-${i}`, {
                autoAlpha: 0,
                duration: fadeOutTime / 1000,
                ease: "power2.out",
              })
            }
          })
      }
    },
    { dependencies: [slides, activeSlide] },
  )

  // Ensure splitInstances are cleaned up on unmount
  // useEffect(() => {
  //   return () => {
  //     splitInstances.current.forEach((instance) => instance.revert())
  //   }
  // }, [])

  return (
    <div
      id="orbit-copy-div"
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        display: "grid",
        placeItems: "start end",
        placeSelf: "start end",
        padding: "0rem 7.5rem",
        columnGap: "4rem",
        gridAutoFlow: "column",
        zIndex: 2,
        overflow: "hidden",
      }}
    >
      {slides &&
        slides?.length > 0 &&
        slides.map((slide, i) => {
          const { title, subtitle } = slide
          return (
            <div
              // className={`orbit-copy`}
              id={`orbit-copy-${i}`}
              key={i}
              style={{
                gridColumn: 1,
                gridRow: "1 / -1",
                display: "grid",
                placeSelf: "end",
                placeItems: "end",
                visibility: "hidden",
                opacity: 0,
                fontSize: "1.5rem",
                lineHeight: "2rem",
                color: globalStyles.col.white,
                textAlign: "right",
                fontFamily: "Baskerville",
              }}
            >
              {title && (
                <span
                  id={`orbit-copy-${i}-title`}
                  style={{
                    fontFamily: "Quiche Sans Regular",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontSize: "3.5rem",
                    paddingBottom: "2rem",
                  }}
                >
                  {title}
                </span>
              )}
              {subtitle && (
                <div
                  style={{
                    width: "45rem",
                  }}
                >
                  <span id={`orbit-copy-${i}-subtitle`}>{subtitle}</span>
                </div>
              )}
            </div>
          )
        })}
      <div
        style={{
          placeSelf: "end start",
          gridColumn: 2,
          gridRow: "1 / -1",
          width: "3px",
          //   height: "42vh",
          height: "80vh",
          backgroundColor: globalStyles.col.gold,
        }}
      />
    </div>
  )
}
